var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            position: "relative",
            "z-index": "1",
          },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "button-left-class_search",
              attrs: { filterable: "", placeholder: "Chọn loại App" },
              on: {
                change: function ($event) {
                  return _vm.searchHeaderMethodTemplate()
                },
              },
              model: {
                value: _vm.getAllTemplateAppRequest.appType,
                callback: function ($$v) {
                  _vm.$set(_vm.getAllTemplateAppRequest, "appType", $$v)
                },
                expression: "getAllTemplateAppRequest.appType",
              },
            },
            _vm._l(_vm.appTypeList, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { value: item.key, label: item.value },
              })
            }),
            1
          ),
          _c(
            "el-input",
            {
              staticStyle: { width: "300px" },
              attrs: { placeholder: "Nhập tên gói", clearable: "" },
              on: {
                clear: function ($event) {
                  return _vm.searchHeaderMethodTemplate()
                },
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.searchHeaderMethodTemplate()
                },
              },
              model: {
                value: _vm.getAllTemplateAppRequest.nameTemplate,
                callback: function ($$v) {
                  _vm.$set(_vm.getAllTemplateAppRequest, "nameTemplate", $$v)
                },
                expression: "getAllTemplateAppRequest.nameTemplate",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: {
                  click: function ($event) {
                    return _vm.searchHeaderMethodTemplate()
                  },
                },
                slot: "append",
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingDataTemplate,
                expression: "loadingDataTemplate",
              },
            ],
            ref: "multipleTable",
            attrs: {
              "empty-text": _vm.textTableTemplateAppTab,
              "element-loading-text": _vm.$tableLoading,
              "element-loading-spinner": "el-icon-loading",
              "element-loading-background": "rgba(255,255,255, 0)",
              "highlight-current-row": "",
              data: _vm.templateAppList,
              "header-cell-style": _vm.tableHeaderColor,
              "max-height": _vm.$tableMaxHeight,
              border: "",
            },
            on: { "selection-change": _vm.handleSelectionChangeTemplate },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "selection",
                align: "center",
                fixed: "",
                width: "80",
              },
            }),
            _c("el-table-column", {
              attrs: {
                fixed: "",
                type: "index",
                label: "STT",
                width: "80",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "Tên gói", "min-width": "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(scope.row.id) +
                            " - " +
                            _vm._s(scope.row.nameTemplate)
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Mô tả gói", "min-width": "300" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.descriptionTemplate)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Nguồn", width: "200", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.typeTemplate))]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "Phiên bản",
                "min-width": "150",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.version))])]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "Mặc định hệ thống",
                width: "150",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-checkbox", {
                        on: {
                          change: function ($event) {
                            return _vm.setDefaultTemplate(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.defaultTemplate,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "defaultTemplate", $$v)
                          },
                          expression: "scope.row.defaultTemplate",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                fixed: "right",
                label: "Tác vụ",
                width: "300",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.openDetailTemplateAppDialog(scope.row)
                            },
                          },
                        },
                        [_vm._v("Chi tiết\n          ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: scope.row.typeTemplate === "system",
                            size: "mini",
                            type: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openUpdateTemplateAppDialog(scope.row)
                            },
                          },
                        },
                        [_vm._v("Sửa\n          ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: scope.row.typeTemplate === "system",
                            size: "mini",
                            type: "danger",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteTemplateAppMethod(scope.row)
                            },
                          },
                        },
                        [_vm._v("Xóa\n          ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "el-row",
          { staticStyle: { "margin-top": "15px" } },
          [
            _c(
              "el-col",
              { attrs: { span: 9, offset: 11 } },
              [
                _c("el-pagination", {
                  staticStyle: {
                    "margin-right": "5.2rem",
                    "margin-left": "auto",
                  },
                  attrs: {
                    background: "",
                    "current-page": _vm.currentPagePagination,
                    "page-sizes": _vm.$pageListDefaultNew,
                    "page-size": _vm.sizeLimit,
                    layout: "prev, pager,sizes, next",
                    total: _vm.sizeLimit * _vm.totalPages,
                  },
                  on: {
                    "size-change": _vm.handleSizeChangeTemplate,
                    "current-change": _vm.handleCurrentChangeTemplate,
                    "update:currentPage": function ($event) {
                      _vm.currentPagePagination = $event
                    },
                    "update:current-page": function ($event) {
                      _vm.currentPagePagination = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-col",
              {
                staticStyle: {
                  display: "inline-flex",
                  "justify-content": "right",
                },
                attrs: { span: 2, offset: 2 },
              },
              [
                _c(
                  "el-dropdown",
                  { on: { command: _vm.handleActionTemplate } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "button-over",
                        attrs: { type: "success" },
                      },
                      [
                        _vm._v(
                          "\n                      Tác vụ\n                      "
                        ),
                        _c("i", { staticClass: "el-icon-caret-bottom" }),
                      ]
                    ),
                    _c(
                      "el-dropdown-menu",
                      [
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "addTemplate" } },
                          [_vm._v("Thêm template\n                      ")]
                        ),
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "deleteManyTemplate" } },
                          [_vm._v("Xoá template\n                      ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("DetailTemplateAppDialogMasterSchool", {
          ref: "DetailTemplateAppDialog",
          attrs: {
            isOpenDetailTemplateAppDialog: _vm.showDetailTemplateAppDialog,
          },
          on: {
            "dialog-close": function ($event) {
              return _vm.closeDetailTemplateAppDialog()
            },
          },
        }),
        _c("UpdateTemplateAppDialogMasterSchool", {
          ref: "UpdateTemplateAppDialog",
          attrs: {
            isOpenUpdateTemplateAppDialog: _vm.showUpdateTemplateAppDialog,
          },
          on: {
            "dialog-close": function ($event) {
              return _vm.closeUpdateTemplateAppDialog()
            },
          },
        }),
        _c("AddTemplateAppDialogMasterSchool", {
          ref: "AddTemplateAppDialog",
          attrs: { isOpenAddTemplateAppDialog: _vm.showAddTemplateAppDialog },
          on: {
            "dialog-close": function ($event) {
              return _vm.closeAddTemplateAppDialog()
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }