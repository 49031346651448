import http from "../http-common";
import http_multipart from "@/http-multipart";

class TemplateService {

    /**
     * Tạo template mới
     */
    createTemplateAppMasterSchool(formData) {
        return http_multipart.post("/template/create", formData);
    }

    /**
     * Lấy danh sách template theo loại app và trường
     */

    getListTemplateMasterSchool(appType, nameTemplate, pageNumber, maxPageItem) {
        return http.get(`/template/getListTemplate?appType=${appType}&nameTemplate=${nameTemplate}&pageNumber=${pageNumber}&maxPageItem=${maxPageItem}`);
    }


    /**
     * Chi tiết Template
     */
    getDetailTemplateMasterSchool(idTemplate) {
        return http.get(`/template/getDetailTemplate?idTemplate=${idTemplate}`);
    }


    /**
     * Xoá 1 Template
     */
    deleteOneTemplateMasterSchool(idTemplate) {
        return http.delete(`/template/delete?idTemplate=${idTemplate}`);
    }

    /**
     * Xoá 1 hoặc nhiều  Template
     */
    deleteOneOrManyTemplateMasterSchool(idTemplateList) {
        return http.delete(`/template/deleteManyTemplate?idTemplateList=${idTemplateList}`);
    }

    /**
     * Sửa Template
     */


    updateTemplateMasterSchool(formData) {
        return http_multipart.put("/template/update", formData);
    }

    /**
     * Đặt trạng thái template mặc định
     */
    setDefaultTemplateMasterSchool(idTemplate) {
        return http.get(`/template/setDefaultTemplate?idTemplate=${idTemplate}`);
    }


}

export default new TemplateService();